import React from 'react'
import { StyledEngineProvider, CssVarsProvider } from '@mui/joy/styles';
import Theme from './src/theme/index.jsx'


export default function MuiRootWrapper({ element }) {
  // console.log(Theme)
  return ( 
    // <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={Theme}>
        {element}
      </CssVarsProvider>
    // </StyledEngineProvider>
  )
}
