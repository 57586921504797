import { extendTheme } from '@mui/joy/styles';

// declare module '@mui/joy/styles' {
//   // No custom tokens found, you can skip the theme augmentation.
// }

// #f5bf26

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        success: {
          solidBg: '#2DA44E',
          solidHoverBg: '#2C974B',
          solidActiveBg: '#298E46',
          // mainChannel: 'black'
        },
        neutral: {
          outlinedBg: '#F6F8FA',
          outlinedHoverBg: '#F3F4F6',
          outlinedActiveBg: 'rgba(238, 239, 242, 1)',
          outlinedBorder: 'rgba(27, 31, 36, 0.15)',
        },
        primary: {
          lightChannel: '#f5bf26',
          500: '#f5bf26',
          600: '#f5bf26',
          700: '#f5bf26',
          800: '#f5bf26',
        },
        focusVisible: 'rgba(3, 102, 214, 0.3)',
        background: {
          body: '#f0f5fe'
        }
      },
    },
  },
  typography: {
    h2: {
      fontSize: '200px',
      fontWeight: 700
    }
  },
})
  
export default theme;